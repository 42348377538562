<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
      <el-form :model="table.where" class="ele-form-search d-flexspabet"
               @keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
        <div>
          <el-button @click="showEdit=true" class="ele-btn-icon addbtn mb-20" size="small"
                     v-if="permission.includes('sys:download:add')">添加版本记录
          </el-button>
        </div>
        <div class="d-flex">
          <el-form-item label="添加日期:" label-width="100px">
            <el-date-picker v-model="table.where.create_time" type="date" value-format="yyyy-MM-dd" placeholder="选择开始时间"
                            clearable>
            </el-date-picker>
            -
            <el-date-picker v-model="table.where.end_time" type="date" value-format="yyyy-MM-dd" placeholder="选择结束时间"
                            clearable>
            </el-date-picker>
          </el-form-item>
          <el-form-item label="使用版:" label-width="62px">
            <el-select v-model="table.where.type" placeholder="请选择" class="ele-fluid" clearable>
              <!-- <el-option label="咱县打车用户版" :value="1" />
              <el-option label="咱县打车司机版" :value="2" />
              <el-option label="咱县打车跑腿版" :value="3" />
              <el-option label="咱县打车商超版" :value="4" /> -->
              <el-option label="可蚁点用户版" :value="1"/>
              <el-option label="可蚁点司机版" :value="2"/>
              <el-option label="可蚁点跑腿版" :value="3"/>
              <el-option label="可蚁点商超版" :value="4"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search"
                       class="ele-btn-icon ml-20">搜索
            </el-button>
            <el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
          </el-form-item>
        </div>
      </el-form>

      <!-- 数据表格 -->
      <ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)"
                      highlight-current-row :stripe=true>
        <template slot-scope="{index}">
          <el-table-column type="selection" width="45" align="center" fixed="left"/>
          <el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
                           show-overflow-tooltip/>
          <el-table-column prop="type_name" label="使用版" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="version" label="安卓版本号" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="oversion" label="IOS版本号" show-overflow-tooltip min-width="120"/>
          <el-table-column prop="constraint_status" label="是否强制更新" show-overflow-tooltip min-width="120"/>
          <el-table-column label="添加时间" show-overflow-tooltip min-width="160">
            <template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
          </el-table-column>
          <el-table-column label="操作" width="200px" align="center" :resizable="false" fixed="right">
            <template slot-scope="{row}">
              <el-link @click="edit(row)" icon="el-icon-view" type="primary" :underline="false"
                       v-if="permission.includes('sys:download:edit')">查看
              </el-link>
              <el-link @click="remove(row)" slot="reference" icon="el-icon-delete" type="danger"
                       :underline="false" v-if="permission.includes('sys:download:delete')">删除
              </el-link>
            </template>
          </el-table-column>
        </template>
      </ele-data-table>
    </el-card>
    <!-- 编辑弹窗 -->
    <el-dialog v-dialogDrag :title="editForm.id?'查看版本更新记录':'添加版本更新记录'" :visible.sync="showEdit"
               @closed="[editForm={},android='']"
               :destroy-on-close="true" :lock-scroll="false">
      <el-card shadow="never">
        <el-form :model="editForm" ref="editForm" :rules="editRules" label-width="100px">

          <el-form-item label="使用版：" prop="type" label-width='160px'>
            <el-select v-model="editForm.type" placeholder="请选择使用版" class="ele-block w-400" clearable
                       :disabled="editForm.id">
              <el-option label="可蚁点用户版" :value="1"/>
              <el-option label="可蚁点司机版" :value="2"/>
              <el-option label="可蚁点跑腿版" :value="3"/>
              <el-option label="可蚁点商超版" :value="4"/>
            </el-select>
          </el-form-item>
          <!--          action="http://test.api.mp.livingtrip.uyl.cn/index.php/upload/uploadFile"-->
          <!--          action="http://element.cn/index.php/upload/uploadFile"-->
          <el-form-item label="安卓更新地址：" label-width='160px' v-if="android">
            <el-input v-model="android" placeholder="请输入安卓更新地址" clearable class="w-400" disabled/>
          </el-form-item>
          <!--          上线改-->
          <el-form-item label="安卓更新地址：" label-width='160px' v-else>
            <div style="display:flex">
              <el-input v-model="editForm.android" placeholder="请输入安卓更新地址" clearable class="w-400"
                        :disabled="editForm.id"/>
              <el-upload class="upload-dem ml-10" action="https://tv3pbmkeydeal.harsineos.com/index.php/upload/uploadFile" :header="userInfo"
                         :on-success="handleAvatarSuccess"
                         :on-change="handleChange" :disabled="editForm.id" accept=".apk" :before-upload="beforeUpload">
                <el-button size="small" type="primary" v-if="!editForm.id">点击上传</el-button>
                <span slot="tip" class="el-upload__tip" style="color: red;">（只能上传.apk格式的文件）</span>
              </el-upload>
            </div>
          </el-form-item>
          <!--					<el-form-item label="安卓更新地址：" label-width='160px' v-else>-->
          <!--						<div style="display:flex">-->
          <!--							<el-input v-model="editForm.android" placeholder="请输入安卓更新地址" clearable class="w-400" :disabled="editForm.id" />-->
          <!--							<el-upload class="upload-dem ml-10"-->
          <!--								action="https://test.api.mp.livingtrip.uyl.cn/index.php/upload/uploadFile" :header="userInfo" :on-success="handleAvatarSuccess"-->
          <!--								:on-change="handleChange" :disabled="editForm.id" accept=".apk" :before-upload="beforeUpload">-->
          <!--								<el-button size="small" type="primary" v-if="!editForm.id">点击上传</el-button>-->
          <!--								<span slot="tip" class="el-upload__tip" style="color: red;">（只能上传.apk格式的文件）</span>-->
          <!--							</el-upload>-->
          <!--						</div>-->
          <!--					</el-form-item>-->
          <el-form-item label="苹果更新地址：" prop="ios" label-width='160px'>
            <el-input v-model="editForm.ios" placeholder="请输入IOS更新地址" clearable class="w-400"
                      :disabled="editForm.id"/>
          </el-form-item>
          <el-form-item label="安卓版版本号：" prop="version" label-width='160px'>
            <el-input v-model="editForm.version" placeholder="请输入安卓版本号" clearable class="w-400"
                      :disabled="editForm.id"/>
          </el-form-item>
          <el-form-item label="苹果版版本号：" prop="oversion" label-width='160px'>
            <el-input v-model="editForm.oversion" placeholder="请输入IOS版本号" clearable class="w-400"
                      :disabled="editForm.id"/>
          </el-form-item>
          <el-form-item label="是否强制更新：" prop="constraint" label-width='160px'>
            <el-select v-model="editForm.constraint" placeholder="请选择更新方式" class="ele-block w-400" clearable
                       :disabled="editForm.id">
              <el-option label="强制更新" :value="1"/>
              <el-option label="不强制更新" :value="2"/>
            </el-select>
          </el-form-item>
          <el-form-item label="本次更新描述：" prop="description" label-width='160px'>
            <el-input :rows="10" v-model="editForm.description" placeholder="请输入本次更新描述" type="textarea"
                      clearable class="w-400" :disabled="editForm.id"/>
          </el-form-item>
        </el-form>
      </el-card>
      <div slot="footer" v-if="!editForm.id">
        <el-button @click="showEdit=false">取消</el-button>
        <el-button type="primary" @click="save">保存</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  mapGetters
} from 'vuex'
import  Config  from "@/config/setting.js"

export default {
  name: 'SysDownload',
  data () {
    return {
      webUrl:'',
      table: {
        url: '/download/index',
        where: {}
      }, // 表格配置
      choose: [], // 表格选中数据
      showEdit: false, // 是否显示表单弹窗
      editForm: {}, // 表单数据
      editRules: { // 表单验证规则
        android: [{
          required: true,
          message: '请输入安卓更新地址',
          trigger: 'blur'
        }],
        type: [{
          required: true,
          message: '请选择使用版',
          trigger: 'change'
        }],
        // ios: [{
        // 	required: true,
        // 	message: '请输入苹果更新地址',
        // 	trigger: 'blur'
        // }],
        version: [{
          required: true,
          message: '请输入安卓版版本号',
          trigger: 'blur'
        }],
        oversion: [{
          required: true,
          message: '请输入苹果版版本号',
          trigger: 'blur'
        }],
        constraint: [{
          required: true,
          message: '请选择是否强制更新',
          trigger: 'change'
        }],
        description: [{
          required: true,
          message: '请输入本次更新描述',
          trigger: 'blur'
        }],
      },
      userInfo: {},
      header: {
        'Content_type': 'multipart/form-data',
      },
      file: [],
      formData: [],
      android: '',
      is_upload: false,
    }
  },
  created () {
    var Accept = 'application/json, text/plain, */*'
    var token = 'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJqd3RfeWgiLCJleHAiOjE2MzAyODg1NTIsInN1YiI6IllIIiwiYXVkIjoiZXZlcnkiLCJuYmYiOjE2Mjk2ODM3NTIsImlhdCI6MTYyOTY4Mzc1MiwianRpIjoxMDAwMSwidWlkIjoxfQ._LR36-HqeBSfcWlpklXoLRJ5kGOcGaqW8d3EY7cfZO0'
    this.webUrl = Config.baseURL+"upload/uploadFile";
    
    this.userInfo.Authorization = token
    this.userInfo.Accept = Accept
  },
  computed: {
    ...mapGetters(['permission']),
  },
  mounted () {},
  methods: {
    // 限制文件类型
    beforeUpload (file) {
      this.is_upload = true
      const fileSuffix = file.name.substring(file.name.lastIndexOf('.') + 1)
      const whiteList = ['apk']
      if (whiteList.indexOf(fileSuffix) === -1) {
        this.$message.error('上传的文件只能是 .apk 格式!')
        return false
      }
    },
    handleChange (file, fileList) {
      //console.log('file-----info',file);
      this.is_upload = true
      this.fileList = fileList.slice(-3)
    },
    handleAvatarSuccess (response, file, fileList) {
      //console.log('-------1234rrr--------------',response)
      this.android = response
      this.editForm.android = response
    },
    /**
     * 显示编辑
     */
    edit (row) {
      this.$http.get('/download/info?id=' + row.id).then(res => {
        if (res.data.code === 0) {
          this.editForm = res.data.data
          this.showEdit = true
        } else {
          this.$message.error(res.data.msg)
        }
      }).catch(e => {
        this.$message.error(e.message)
      })
    },
    /**
     * 保存编辑
     */
    save () {
      this.$refs['editForm'].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true
          })
          if (this.android) {
            this.editForm.android = this.android
          }
          if (this.is_upload) {
            if (!this.editForm.android) {
              loading.close()
              return this.$message.error('请先上传apk')
            }
          }
          this.$http.post('/download/editdata', this.editForm).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.showEdit = false
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        } else {
          return false
        }
      })
    },
    /**
     * 刪除(批量刪除)
     */
    remove (row) {
      if (!row) { // 批量删除
        if (this.choose.length === 0) return this.$message.error('请至少选择一条数据')
        let ids = this.choose.map(d => d.id)
        this.$confirm('确定要删除选中的版本更新记录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/download/delete', {
            id: ids
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        }).catch(() => 0)
      } else { // 单个删除
        this.$confirm('确定要删除选中的版本更新记录吗?', '提示', {
          type: 'warning'
        }).then(() => {
          const loading = this.$loading({
            lock: true
          })
          this.$http.post('/download/delete', {
            id: row.id
          }).then(res => {
            loading.close()
            if (res.data.code === 0) {
              this.$message({
                type: 'success',
                message: res.data.msg
              })
              this.$refs.table.reload()
            } else {
              this.$message.error(res.data.msg)
            }
          }).catch(e => {
            loading.close()
            this.$message.error(e.message)
          })
        })
      }
    },
  }
}
</script>

<style scoped>
.ele-block >>> .el-upload,
.ele-block >>> .el-upload-dragger {
  width: 100%;
}

/deep/ .el-dialog {
  margin-top: 30px !important;
}
</style>
